import React from 'react'
import loadable from "@loadable/component";

const ArticlePreview = loadable(() => import("../../templates/articlePreview.amp"));

const WhatNextMobileAMP = (props) => {

    return (
        <div data-datocms-noindex>
            <div className="py-4 text-denim mt-4 text-2xl font-bold">WHAT TO READ NEXT...</div>
            <div className="container-grid">
                {props.recommendedPosts.map((item, index) => {
                    return <ArticlePreview
                        key={index}
                        className={"border-b-default border-gray-400 pt-2 relative clear-both min-h-140"}
                        headingClass={"font-serif text-xl leading-snug"}
                        imageClass="w-1/3 ml-2 float-right pt-4"
                        data={item}
                        noBody={true}
                        noAuthor={true}
                        wrappedImg={false}/>
                })}
            </div>
        </div>
    )
}

export default WhatNextMobileAMP
